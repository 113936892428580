@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2d333a;
  line-height: 1.6;
  background-color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  background-color: rgba(0, 0, 0, 0.04);
  padding: 2px 4px;
  border-radius: 4px;
}

a {
  color: #10a37f;
  text-decoration: none;
  transition: color 0.2s ease;
}

a:hover {
  color: #0a8c6d;
}

/* Smooth scrolling */
html {
  scroll-behavior: smooth;
}

/* Selection color */
::selection {
  background-color: rgba(16, 163, 127, 0.1);
  color: #10a37f;
}

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

/* Focus outline */
:focus {
  outline: 2px solid rgba(16, 163, 127, 0.5);
  outline-offset: 2px;
}

/* Transition for interactive elements */
button, 
input, 
select, 
textarea {
  transition: all 0.2s ease;
}

/* Container width */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
}

/* Section spacing */
section {
  padding: 64px 0;
}

/* Responsive images */
img {
  max-width: 100%;
  height: auto;
  display: block;
}

.card {
  perspective: 1000px; /* Enable 3D perspective */
}

.card .MuiCard-root {
  transition: transform 0.3s; /* Animation transition */
}

.card:hover .MuiCard-root {
  transform: rotateY(10deg) rotateX(5deg); /* Rotate on hover for 3D effect */
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2); /* Add shadow for depth */
}
